<template>
  <div class="page-grid">
    <category-title :category="category" />

    <page-grid-internal :category="category" />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_TEXT"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "PageGrid",
  mixins: [category, deliveryReactive, login],
  components: {
    CategoryTitle
  }
  // metaInfo() {
  //   const title = get(
  //     this.category,
  //     "metaData.category_seo.SEO_TITLE",
  //     this.category ? this.category.name : ""
  //   );
  //   const description = get(
  //     this.category,
  //     "metaData.category_seo.SEO_DESCRIPTION",
  //     "Categoria: " + this.category ? this.category.name : ""
  //   );
  //   return {
  //     title: title,
  //     meta: [
  //       {
  //         vmid: "description",
  //         name: "description",
  //         content: description
  //       },
  //       // Open Graph Metas
  //       {
  //         vmid: "og:locale",
  //         property: "og:locale",
  //         content: "it_IT"
  //       },
  //       {
  //         vmid: "og:title",
  //         property: "og:title",
  //         content: title
  //       },
  //       {
  //         vmid: "og:url",
  //         property: "og:url",
  //         content: location.href
  //       },
  //       {
  //         vmid: "og:image",
  //         property: "og:image",
  //         content:
  //           this.category.metaData?.category_info?.HEADER_IMAGE ||
  //           this.category.metaData?.category_info?.DESCRIPTION_IMAGE ||
  //           "https://" + window.location.host + global.config.socialLogo ||
  //           "/logo/social-logo.png"
  //       },
  //       {
  //         vmid: "og:image:alt",
  //         property: "og:image:alt",
  //         content: description
  //       },
  //       {
  //         vmid: "og:site_name",
  //         property: "og:site_name",
  //         content: "iper.it"
  //       },
  //       // Twitter Metas
  //       {
  //         vmid: "twitter:card",
  //         name: "twitter:card",
  //         content: "summary_large_image"
  //       },
  //       {
  //         vmid: "twitter:title",
  //         name: "twitter:title",
  //         content: title
  //       },
  //       {
  //         vmid: "twitter:image",
  //         name: "twitter:image",
  //         content:
  //           "https://" + window.location.host + global.config.socialLogo ||
  //           "/logo/social-logo.png"
  //       },
  //       {
  //         vmid: "twitter:url",
  //         name: "twitter:url",
  //         content: location.href
  //       }
  //     ]
  //   };
  // }
};
</script>
